const formatDataTransferValue = (value) => {
    // some error condition
    if (value < 0) {
        return "N/A";
    }

    let gigabytes = value / 1e9;

    return Math.ceil(gigabytes);
};

export { formatDataTransferValue };
