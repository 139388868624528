export const getKpiName = (kpiNickName, minutePrecision, organizationId, createdBy, assetId, eventId) => {
    let kpiName = "";

    console.log(
        "GETKPINAME: kpi " + kpiNickName + ", organizationId, createdBy, assetId, eventId, minutePrecision",
        organizationId,
        createdBy,
        assetId,
        eventId,
        minutePrecision
    );

    if (assetId) {
        kpiName = getAssetKpiName(kpiNickName, minutePrecision);
        // console.log("ASSET: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    } else if (eventId) {
        kpiName = getEventKpiName(kpiNickName, minutePrecision);
        // console.log("EVENT: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    } else if (createdBy) {
        kpiName = getCreatorKpiName(kpiNickName, minutePrecision);
        // console.log("CREATOR: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    } else {
        kpiName = getOrganizationKpiName(kpiNickName, minutePrecision);
        // console.log("ORG: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    }

    if (kpiName === "") {
        console.error(
            "getKpiName: kpi " + kpiNickName + " does not exist, organizationId, createdBy, assetId, eventId, minutePrecision",
            organizationId,
            createdBy,
            assetId,
            eventId,
            minutePrecision
        );
    }

    return kpiName;
};

const getAssetKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-asset-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-asset-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-asset-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-asset-viewer-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }

    if (minutePrecision) {
        switch (kpiNickName) {
            case "impressionsVOD":
                kpiName = "vod-player-loads-asset-1-min";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-asset-1-min";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-asset-1-min";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-asset-viewer-1-min";
                break;

            default:
                break;
        }
    } else {
        switch (kpiNickName) {
            case "impressionsVOD":
                kpiName = "vod-player-loads-asset-1-day";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-asset-1-day";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-asset-1-day";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-asset-viewer-1-day";
                break;

            default:
                break;
        }
    }

    if (kpiName === "") {
        console.error("getAssetKpiName: kpi " + kpiNickName + " does not exist for minutePrecision '" + minutePrecision + "'");
    }

    return kpiName;
};

const getEventKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-event-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-event-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-event-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-event-viewer-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }

    if (minutePrecision) {
        switch (kpiNickName) {
            case "impressions":
                kpiName = "all-player-loads-event-1-min";
                break;

            case "impressionsVOD":
                kpiName = "vod-player-loads-event-1-min";
                break;

            case "impressionsLIVE":
                kpiName = "live-player-loads-event-1-min";
                break;

            case "plays":
                kpiName = "all-player-starts-event-1-min";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-event-1-min";
                break;

            case "playsLIVE":
                kpiName = "live-player-starts-event-1-min";
                break;

            case "watchedMinutes":
                kpiName = "all-watched-minutes-event-1-min";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-event-1-min";
                break;

            case "watchedMinutesLIVE":
                kpiName = "live-watched-minutes-event-1-min";
                break;

            case "uniqueViewers":
                kpiName = "all-unique-event-viewer-1-min";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-event-viewer-1-min";
                break;

            case "uniqueViewersLIVE":
                kpiName = "live-unique-event-viewer-1-min";
                break;

            default:
                break;
        }
    } else {
        switch (kpiNickName) {
            case "impressions":
                kpiName = "all-player-loads-event-1-day";
                break;

            case "impressionsVOD":
                kpiName = "vod-player-loads-event-1-day";
                break;

            case "impressionsLIVE":
                kpiName = "live-player-loads-event-1-day";
                break;

            case "plays":
                kpiName = "all-player-starts-event-1-day";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-event-1-day";
                break;

            case "playsLIVE":
                kpiName = "live-player-starts-event-1-day";
                break;

            case "watchedMinutes":
                kpiName = "all-watched-minutes-event-1-day";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-event-1-day";
                break;

            case "watchedMinutesLIVE":
                kpiName = "live-watched-minutes-event-1-day";
                break;

            case "uniqueViewers":
                kpiName = "all-unique-event-viewer-1-day";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-event-viewer-1-day";
                break;

            case "uniqueViewersLIVE":
                kpiName = "live-unique-event-viewer-1-day";
                break;

            default:
                break;
        }
    }

    if (kpiName === "") {
        console.error("getEventKpiName: kpi " + kpiNickName + " does not exist for minutePrecision '" + minutePrecision + "'");
    }

    return kpiName;
};

const getCreatorKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-creator-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-creator-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-creator-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-creator-viewer-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }

    if (minutePrecision) {
        switch (kpiNickName) {
            case "impressions":
                kpiName = "all-player-loads-creator-1-min";
                break;

            case "impressionsVOD":
                kpiName = "vod-player-loads-creator-1-min";
                break;

            case "impressionsLIVE":
                kpiName = "live-player-loads-creator-1-min";
                break;

            case "plays":
                kpiName = "all-player-starts-creator-1-min";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-creator-1-min";
                break;

            case "playsLIVE":
                kpiName = "live-player-starts-creator-1-min";
                break;

            case "watchedMinutes":
                kpiName = "all-watched-minutes-creator-1-min";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-creator-1-min";
                break;

            case "watchedMinutesLIVE":
                kpiName = "live-watched-minutes-creator-1-min";
                break;

            case "uniqueViewers":
                kpiName = "all-unique-creator-viewer-1-min";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-creator-viewer-1-min";
                break;

            case "uniqueViewersLIVE":
                kpiName = "live-unique-creator-viewer-1-min";
                break;

            default:
                break;
        }
    } else {
        switch (kpiNickName) {
            case "impressions":
                kpiName = "all-player-loads-creator-1-day";
                break;

            case "impressionsVOD":
                kpiName = "vod-player-loads-creator-1-day";
                break;

            case "impressionsLIVE":
                kpiName = "live-player-loads-creator-1-day";
                break;

            case "plays":
                kpiName = "all-player-starts-creator-1-day";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-creator-1-day";
                break;

            case "playsLIVE":
                kpiName = "live-player-starts-creator-1-day";
                break;

            case "watchedMinutes":
                kpiName = "all-watched-minutes-creator-1-day";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-creator-1-day";
                break;

            case "watchedMinutesLIVE":
                kpiName = "live-watched-minutes-creator-1-day";
                break;

            case "uniqueViewers":
                kpiName = "all-unique-creator-viewer-1-day";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-creator-viewer-1-day";
                break;

            case "uniqueViewersLIVE":
                kpiName = "live-unique-creator-viewer-1-day";
                break;

            default:
                break;
        }
    }

    if (kpiName === "") {
        console.error("getCreatoreKpiName: kpi " + kpiNickName + " does not exist for minutePrecision '" + minutePrecision + "'");
    }

    return kpiName;
};

export const getOrganizationKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-organization-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-organization-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-organization-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-organization-viewer-total";
            break;

        case "totalDataTransfer":
            kpiName = "cdn-all-data-transfer-organization-total";
            break;

        case "totalDataTransferVOD":
            kpiName = "cdn-vod-data-transfer-organization-total";
            break;

        case "totalDataTransferLIVE":
            kpiName = "cdn-live-data-transfer-organization-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }

    if (minutePrecision) {
        switch (kpiNickName) {
            case "impressions":
                kpiName = "all-player-loads-organization-1-min";
                break;

            case "impressionsVOD":
                kpiName = "vod-player-loads-organization-1-min";
                break;

            case "impressionsLIVE":
                kpiName = "live-player-loads-organization-1-min";
                break;

            case "plays":
                kpiName = "all-player-starts-organization-1-min";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-organization-1-min";
                break;

            case "playsLIVE":
                kpiName = "live-player-starts-organization-1-min";
                break;

            case "watchedMinutes":
                kpiName = "all-watched-minutes-organization-1-min";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-organization-1-min";
                break;

            case "watchedMinutesLIVE":
                kpiName = "live-watched-minutes-organization-1-min";
                break;

            case "uniqueViewers":
                kpiName = "all-unique-organization-viewer-1-min";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-organization-viewer-1-min";
                break;

            case "uniqueViewersLIVE":
                kpiName = "live-unique-organization-viewer-1-min";
                break;

            case "dataTransfer":
                kpiName = "cdn-all-data-transfer-organization-1-min";
                break;

            case "dataTransferVOD":
                kpiName = "cdn-vod-data-transfer-organization-1-min";
                break;

            case "dataTransferLIVE":
                kpiName = "cdn-live-data-transfer-organization-1-min";
                break;

            default:
                break;
        }
    } else {
        switch (kpiNickName) {
            case "impressions":
                kpiName = "all-player-loads-organization-1-day";
                break;

            case "impressionsVOD":
                kpiName = "vod-player-loads-organization-1-day";
                break;

            case "impressionsLIVE":
                kpiName = "live-player-loads-organization-1-day";
                break;

            case "plays":
                kpiName = "all-player-starts-organization-1-day";
                break;

            case "playsVOD":
                kpiName = "vod-player-starts-organization-1-day";
                break;

            case "playsLIVE":
                kpiName = "live-player-starts-organization-1-day";
                break;

            case "watchedMinutes":
                kpiName = "all-watched-minutes-organization-1-day";
                break;

            case "watchedMinutesVOD":
                kpiName = "vod-watched-minutes-organization-1-day";
                break;

            case "watchedMinutesLIVE":
                kpiName = "live-watched-minutes-organization-1-day";
                break;

            case "uniqueViewers":
                kpiName = "all-unique-organization-viewer-1-day";
                break;

            case "uniqueViewersVOD":
                kpiName = "vod-unique-organization-viewer-1-day";
                break;

            case "uniqueViewersLIVE":
                kpiName = "live-unique-organization-viewer-1-day";
                break;

            case "dataTransfer":
                kpiName = "cdn-all-data-transfer-organization-1-day";
                break;

            case "dataTransferVOD":
                kpiName = "cdn-vod-data-transfer-organization-1-day";
                break;

            case "dataTransferLIVE":
                kpiName = "cdn-live-data-transfer-organization-1-day";
                break;

            default:
                break;
        }
    }

    if (kpiName === "") {
        console.error("getOrganizationKpiName: kpi " + kpiNickName + " does not exist for minutePrecision '" + minutePrecision + "'");
    }

    return kpiName;
};

export const getTotalKpiName = (kpiNickName, minutePrecision, organizationId, createdBy, assetId, eventId) => {
    let kpiName = "";

    console.log(
        "getTotalKpiName: kpi " + kpiNickName + ", organizationId, createdBy, assetId, eventId, minutePrecision",
        organizationId,
        createdBy,
        assetId,
        eventId,
        minutePrecision
    );

    if (assetId) {
        kpiName = getAssetTotalKpiName(kpiNickName, minutePrecision);
        console.log("ASSET: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    } else if (eventId) {
        kpiName = getEventTotalKpiName(kpiNickName, minutePrecision);
        console.log("EVENT: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    } else if (createdBy) {
        kpiName = getCreatorTotalKpiName(kpiNickName, minutePrecision);
        console.log("CREATOR: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    } else {
        kpiName = getOrganizationTotalKpiName(kpiNickName, minutePrecision);
        console.log("ORG: got kpiName " + kpiName + " for kpiNickName " + kpiNickName);
    }

    if (kpiName === "") {
        console.error(
            "getTotalKpiName: kpi " + kpiNickName + " does not exist, organizationId, createdBy, assetId, eventId, minutePrecision",
            organizationId,
            createdBy,
            assetId,
            eventId,
            minutePrecision
        );
    }

    return kpiName;
};

const getAssetTotalKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-asset-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-asset-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-asset-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-asset-viewer-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }
};

const getEventTotalKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalUniqueViewers":
            kpiName = "all-unique-event-viewer-total";
            break;

        case "totalWatchedMinutes":
            kpiName = "all-watched-minutes-event-total";
            break;

        case "totalImpressions":
            kpiName = "all-player-loads-event-total";
            break;

        case "totalPlays":
            kpiName = "all-player-starts-event-total";
            break;

        case "totalUniqueViewersLIVE":
            kpiName = "live-unique-event-viewer-total";
            break;

        case "totalWatchedMinutesLIVE":
            kpiName = "live-watched-minutes-event-total";
            break;

        case "totalImpressionsLIVE":
            kpiName = "live-player-loads-event-total";
            break;

        case "totalPlaysLIVE":
            kpiName = "live-player-starts-event-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-event-viewer-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-event-total";
            break;

        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-event-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-event-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }
};

const getCreatorTotalKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-creator-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-creator-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-creator-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-creator-viewer-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }
};

const getOrganizationTotalKpiName = (kpiNickName, minutePrecision) => {
    let kpiName = "";

    switch (kpiNickName) {
        case "totalImpressionsVOD":
            kpiName = "vod-player-loads-organization-total";
            break;

        case "totalPlaysVOD":
            kpiName = "vod-player-starts-organization-total";
            break;

        case "totalWatchedMinutesVOD":
            kpiName = "vod-watched-minutes-organization-total";
            break;

        case "totalUniqueViewersVOD":
            kpiName = "vod-unique-organization-viewer-total";
            break;

        default:
            break;
    }

    if (kpiName !== "") {
        return kpiName;
    }
};
