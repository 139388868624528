import React, { useEffect, useState } from "react";
// import Select from "react-select";
// import Loader from "react-loader-spinner";
// import { useHistory } from "react-router-dom";
import moment from "moment";

import ValueCard from "./Cards/ValueCard";

import { getKpiFromOpenSearch, getKpiGraphData } from "../../scripts/openSearchHandler";
import { formatDataTransferValue } from "../../scripts/dataTransferUtils";

// import { useMyContext } from "../../contexts/StateHolder";

import GenericChart from "../Analytics/Charts/GenericChart";

import Button from "../Analytics/Button";
import ModalDateRangePicker from "../Analytics/ModalDateRangePicker";
import Backdrop from "../Analytics/Backdrop";

// import MySelect from "./MySelect";

import SelectedDateRangeHeading from "../Analytics/SelectedDateRangeHeading";

import classes from "./CDNAnalyticsDashboard.module.css";

const CDNAnalyticsDashboard = (props) => {
    const organizationId = props.organizationId;
    // const secret = props.secret;
    // const userId = props.userId;
    const assetId = null;
    const eventId = null;
    const createdBy = null;

    console.log("CDNAnalyticsDashboard: orgId", organizationId);

    const getISODate = (d) => {
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const day = d.getDate();

        const yearStr = "" + year;
        const monthStr = "" + (month >= 10 ? "" + month : "0" + month);
        const dayStr = "" + (day >= 10 ? "" + day : "0" + day);

        return "" + yearStr + "-" + monthStr + "-" + dayStr;
    };

    const startMoment1 = new moment().startOf("month");
    const endMoment1 = new moment().endOf("month");
    const startDate1 = startMoment1.toDate();
    const endDate1 = endMoment1.toDate();

    const d = new Date();
    d.setDate(0); // 0 will result in the last day of the last period
    d.setDate(1); // 1 will result in the first day of the month

    const startMoment2 = new moment(d).startOf("month");
    const endMoment2 = new moment(d).endOf("month");
    const startDate2 = startMoment2.toDate();
    const endDate2 = endMoment2.toDate();

    // These have a big negative value so we get the spinner
    const [dataTransferLIVEThisPeriod, setDataTransferLIVEThisPeriod] = useState(-1);
    const [dataTransferLIVELastPeriod, setDataTransferLIVELastPeriod] = useState(-1);

    const [dataTransferVODThisPeriod, setDataTransferVODThisPeriod] = useState(-1);
    const [dataTransferVODLastPeriod, setDataTransferVODLastPeriod] = useState(-1);

    const [dataTransferOtherThisPeriod, setDataTransferOtherThisPeriod] = useState(-1);
    const [dataTransferOtherLastPeriod, setDataTransferOtherLastPeriod] = useState(-1);

    const [dataTransferThisPeriod, setDataTransferThisPeriod] = useState(-1);
    const [dataTransferLastPeriod, setDataTransferLastPeriod] = useState(-1);

    const [dataTransferLIVEValues, setDataTransferLIVEValues] = useState(null);
    const [dataTransferVODValues, setDataTransferVODValues] = useState(null);
    const [dataTransferValues, setDataTransferValues] = useState(null);

    const [modalDateRangePickerIsOpen, setModalDateRangePickerIsOpen] = useState(false);

    const [startDateThisPeriod, setStartDateThisPeriod] = useState(startDate1);
    const [endDateThisPeriod, setEndDateThisPeriod] = useState(endDate1);
    const [startDateLastPeriod, setStartDateLastPeriod] = useState(startDate2);
    const [endDateLastPeriod, setEndDateLastPeriod] = useState(endDate2);

    const [startISODateThisPeriod, setStartISODateThisPeriod] = useState(getISODate(startDate1));
    const [endISODateThisPeriod, setEndISODateThisPeriod] = useState(getISODate(endDate1));
    const [startISODateLastPeriod, setStartISODateLastPeriod] = useState(getISODate(startDate2));
    const [endISODateLastPeriod, setEndISODateLastPeriod] = useState(getISODate(endDate2));

    const ONE_DAY = 1000 * 60 * 60 * 24;

    const calculateDays = (startDate, endDate) => {
        const delta = endDate - startDate;
        const days = Math.ceil(delta / ONE_DAY);
        return days;
    };

    // Put all start dates and end dates and related stuff into state variables
    const updateDateRanges = (startDate, endDate) => {
        const startDate1 = startDate;
        const endDate1 = endDate;

        // console.log("startDate1", startDate1);
        // console.log("endDate1", endDate1);

        const days = calculateDays(startDate, endDate);
        // console.log("-------------> days", days);
        const milliseconds = days * ONE_DAY;

        // Put an equally long period of time as the last period
        const startDate2 = new Date(startDate1 - milliseconds);
        const endDate2 = new Date(endDate1 - milliseconds);

        // console.log("startDate2", startDate2);
        // console.log("endDate2", endDate2);

        setStartDateThisPeriod(startDate1);
        setEndDateThisPeriod(endDate1);
        setStartISODateThisPeriod(getISODate(startDate1));
        setEndISODateThisPeriod(getISODate(endDate1));

        setStartDateLastPeriod(startDate2);
        setEndDateLastPeriod(endDate2);
        setStartISODateLastPeriod(getISODate(startDate2));
        setEndISODateLastPeriod(getISODate(endDate2));
    };

    // const startMoment = new moment().startOf("month");
    // const endMoment = new moment().endOf("month");
    // const startDate = startMoment.toDate();
    // const endDate = endMoment.toDate();

    //updateDateRanges(startDate, endDate);

    const getKpiThisPeriod = async (name) => {
        // console.log("-----> getKpiThisPeriod: name, organizationId, createdBy", name, organizationId, createdBy);
        return getKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDateThisPeriod, endDateThisPeriod);
    };
    const getKpiLastPeriod = async (name) => {
        // console.log("-----> getKpiLastPeriod: name, organizationId, createdBy", name, organizationId, createdBy);
        return getKpiFromOpenSearch(name, organizationId, createdBy, assetId, eventId, startDateLastPeriod, endDateLastPeriod);
    };

    useEffect(() => {
        // this period
        const fetchDataDt = async () => {
            const v = await getKpiThisPeriod("dataTransferVOD");
            setDataTransferVODThisPeriod(v);
            const l = await getKpiThisPeriod("dataTransferLIVE");
            setDataTransferLIVEThisPeriod(l);
            const t = await getKpiThisPeriod("dataTransfer");
            setDataTransferThisPeriod(t);
            // Other data transfer is total data transfer minus vod + live, i.e. what is not vod or live, e.g. some api call json data or something.
            let other = t - (l + v);
            setDataTransferOtherThisPeriod(other);
        };
        fetchDataDt();

        // last period
        const fetchDataDtlp = async () => {
            const v = await getKpiLastPeriod("dataTransferVOD");
            setDataTransferVODLastPeriod(v);
            const l = await getKpiLastPeriod("dataTransferLIVE");
            setDataTransferLIVELastPeriod(l);
            const t = await getKpiLastPeriod("dataTransfer");
            setDataTransferLastPeriod(t);
            let other = t - (l + v);
            setDataTransferOtherLastPeriod(other);
        };
        fetchDataDtlp();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, startISODateThisPeriod, endISODateThisPeriod, startISODateLastPeriod, endISODateLastPeriod]);

    useEffect(() => {
        const getKpiData = async (name) => {
            const startDate = startDateLastPeriod;
            const endDate = endDateThisPeriod;
            const data = await getKpiGraphData(name, organizationId, createdBy, assetId, eventId, startDate, endDate);
            return data;
        };

        const fetchGraphData = async () => {
            let vod = await getKpiData("dataTransferVOD");
            vod = vod.map((o) => {
                // console.log("o", o);
                let [k, v] = Object.entries(o)[0];
                // console.log("k, v", k, v);
                return { [k]: v / 1e9 };
            });
            // console.log("vod", vod);
            if (vod) {
                setDataTransferVODValues(vod);
            }
            let live = await getKpiData("dataTransferLIVE");
            live = live.map((o) => {
                // console.log("o", o);
                let [k, v] = Object.entries(o)[0];
                // console.log("k, v", k, v);
                return { [k]: v / 1e9 };
            });

            // console.log("live", live);
            if (live) {
                setDataTransferLIVEValues(live);
            }
            let all = await getKpiData("dataTransfer");
            all = all.map((o) => {
                // console.log("o", o);
                let [k, v] = Object.entries(o)[0];
                // console.log("k, v", k, v);
                return { [k]: v / 1e9 };
            });

            //console.log("all", all);
            if (all) {
                setDataTransferValues(all);
            }
        };
        fetchGraphData();
    }, [organizationId, startDateLastPeriod, endDateThisPeriod]);

    const selectDateRangeButtonHandler = () => {
        setModalDateRangePickerIsOpen(true);
    };

    const closeModalHandler = () => {
        setModalDateRangePickerIsOpen(false);
    };

    const saveModalHandler = (startDate, endDate) => {
        // console.log("saveModalHandler startDate, endDate", startDate, endDate);

        updateDateRanges(startDate, endDate);

        setModalDateRangePickerIsOpen(false);
    };

    return (
        <div className={classes.view}>
            {/* <h1>Video Analytics</h1> */}
            <div className={classes.actions}>
                {/* {userId > 0 && !showAllVideos && <Button color="#0a7aad" text="Show analytics for all videos" onClick={showAllVideosButtonHandler} />}
                    {userId > 0 && showAllVideos && <Button color="#0a7aad" text="Show analytics for my videos" onClick={showOnlyMyVideosButtonHandler} />} */}
                <Button color="#0a7aad" text="Select Date Range" onClick={selectDateRangeButtonHandler} />
                &nbsp;
                {/* {userId > 0 && (
                    // <Select className={classes.select} options={options} value={"all-videos"} defaultValue={"all-videos"} onChange={selectionChangeHandler} />
                    <MySelect options={videoOptions} value={selectedVideos} defaultValue={"all-videos"} onChange={selectionChangeHandler} />
                )}
                &nbsp; */}
                {/* <SearchForAssets organizationId={organizationId} secret={secret} userId={userId} /> */}
                {/* <input
                    type="text"
                    placeholder="Search for assets"
                    className={classes.input}
                    onChange={onSearchStringChange}
                    onKeyDown={onSearchStringKeyDown}
                />
                &nbsp;
                <FontAwesomeIcon icon="search" size="1x" style={{ backgroundColor: "#eceff1" }} onClick={onSearchClick} /> */}
            </div>

            {modalDateRangePickerIsOpen && (
                <ModalDateRangePicker startDate={startDateThisPeriod} endDate={endDateThisPeriod} onOk={saveModalHandler} onCancel={closeModalHandler} />
            )}
            {modalDateRangePickerIsOpen && <Backdrop onCancel={closeModalHandler} />}

            <SelectedDateRangeHeading startDate={startDateThisPeriod} endDate={endDateThisPeriod} periodName={"Current period"} />

            <div className={classes.overviewWrapper14}>
                <ValueCard value={formatDataTransferValue(dataTransferVODThisPeriod)} title={"VOD data transfer in GB"} />
                <ValueCard value={formatDataTransferValue(dataTransferLIVEThisPeriod)} title={"LIVE data transfer in GB"} />
                <ValueCard value={formatDataTransferValue(dataTransferOtherThisPeriod)} title={"Other data transfer in GB"} />
                <ValueCard value={formatDataTransferValue(dataTransferThisPeriod)} title={"Total data transfer in GB"} />
            </div>

            <SelectedDateRangeHeading startDate={startDateLastPeriod} endDate={endDateLastPeriod} periodName={"Previous period"} />

            <div className={classes.overviewWrapper14}>
                <ValueCard value={formatDataTransferValue(dataTransferVODLastPeriod)} title={"VOD data transfer in GB"} />
                <ValueCard value={formatDataTransferValue(dataTransferLIVELastPeriod)} title={"LIVE data transfer in GB"} />
                <ValueCard value={formatDataTransferValue(dataTransferOtherLastPeriod)} title={"Other data transfer in GB"} />
                <ValueCard value={formatDataTransferValue(dataTransferLastPeriod)} title={"Total data transfer in GB"} />
            </div>

            <h2>VOD data transfer in GB</h2>
            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={createdBy}
                        chartType={"dataTransferVOD"}
                        data={[
                            {
                                name: "dataTransferVOD",
                                values: dataTransferVODValues,
                            },
                        ]}
                        startDate={startDateLastPeriod}
                        endDate={endDateThisPeriod}
                    />
                </div>
            </div>

            <h2>LIVE data transfer in GB</h2>
            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={createdBy}
                        chartType={"dataTransferLIVE"}
                        data={[
                            {
                                name: "dataTransferLIVE",
                                values: dataTransferLIVEValues,
                            },
                        ]}
                        startDate={startDateLastPeriod}
                        endDate={endDateThisPeriod}
                    />
                </div>
            </div>

            <h2>Total data transfer in GB</h2>
            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <GenericChart
                        organizationId={organizationId}
                        createdBy={createdBy}
                        chartType={"dataTransfer"}
                        data={[
                            {
                                name: "dataTransfer",
                                values: dataTransferValues,
                            },
                        ]}
                        startDate={startDateLastPeriod}
                        endDate={endDateThisPeriod}
                    />
                </div>
            </div>

            {/* <h2>Multiline Chart</h2>

            <div className={classes.overviewWrapper3}>
                <div className={classes.resultItem3}>
                    <Legend data={legendData} selectedItems={selectedItems} onChange={onChangeSelection} />
                    <MultilineChart organizationId={organizationId} data={chartData} dimensions={dimensions} />
                </div>
            </div> */}

            {/* <LatestAssets organizationId={props.organizationId} secret={props.secret} userId={props.userId} /> */}
            {/* <div className={classes.Assets_dashboard_title}>
                {showLoader && (
                    <div className={classes.Assets_loader}>
                        {" "}
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}
            </div>{" "} */}
            {/* <div className={classes.Assets_dashboard_title}>
                {" "}
                {latestAssets.length > 0 && (
                    <AssetsItem
                        assets={latestAssets}
                        // .filter((event) => event.startTime < startDay && event.startTime > monthAgo)
                        // .sort((a, b) => b.startTime - a.startTime)}
                        heading={"Latest and most popular video assets"}
                        organizationId={props.organizationId}
                        showNoImage={true}
                    />
                )}{" "}
            </div>{" "} */}

            {/* <LatestAndMostPopularAssets
                organizationId={props.organizationId}
                secret={props.secret}
                userId={props.userId}
                latestAssets={latestAssets}
                popularAssets={popularAssets}
            /> */}
        </div>
    );
};

export default CDNAnalyticsDashboard;
